@import 'palette';

/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

* {
  font-family: "Inter", sans-serif;
  letter-spacing: unset !important;
}

/**
styleName: (foregroundColor, backgroundColor)
foregroundColor: used for text
backgroundColor: used for backgrounds and other primary color properties
 */
$color-map: (
  success: (#fff, #6DC54F),
  warning: (#000, #F4D150),
  error: (#fff, #E04A4A),
  info: (#fff, #117AF6)
);

@each $color-name, $colors in $color-map {
  .accent_#{$color-name} {

    // <editor-fold desc="Buttons">
    &.mdc-button,
    &.mat-mdc-stroked-button {
      &:not(:disabled) {
        color: nth($colors, 2);
      }
    }

    &.mat-mdc-unelevated-button:hover,
    &.mat-mdc-raised-button:hover {
      &:not(:disabled) {
        color: nth($colors, 1);
        background-color: nth($colors, 2);
      }
    }

    &.mat-mdc-unelevated-button,
    &.mat-mdc-raised-button,
    &.mat-mdc-fab,
    &.mat-mdc-mini-fab {
      &:not(:disabled) {
        color: nth($colors, 1);
        background-color: nth($colors, 2);
      }
    }

    &.mat-mdc-icon-button {
      &:not(:disabled) {
        color: nth($colors, 2);
      }
    }
    // </editor-fold>

    // <editor-fold desc="Checkbox + checkbox ripple">
    .mdc-checkbox__ripple {
      background-color: nth($colors, 2) !important;
    }
    &.mat-mdc-checkbox-checked {
      .mdc-checkbox__background {
        background-color: nth($colors, 2) !important;
        border-color: nth($colors, 2) !important;
      }
    }
    // indeterminate status
    .mdc-checkbox__native-control:enabled:indeterminate {
      &~.mdc-checkbox__ripple {
        background-color: nth($colors, 2) !important;
      }
      &~.mdc-checkbox__background {
        background-color: nth($colors, 2) !important;
        border-color: nth($colors, 2) !important;
      }
    }
    // </editor-fold>

  }
}
